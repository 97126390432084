import React from 'react';
import { connect } from 'react-redux';
import { Redirect, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { addAlbum, updateAlbum } from '../../../actions/albums';

class AlbumForm extends React.Component {
	constructor(props) {
	  super(props);
	  const captcha = this.generateCaptcha();
	  this.state = {
		id: this.props.album ? this.props.album.id : null,
		title: this.props.album ? this.props.album.title : '',
		description: this.props.album ? this.props.album.description : '',
		rating: 0,
		siisti: this.props.album ? this.props.album.siisti : 0,
		helppo: this.props.album ? this.props.album.helppo : 0,
		cover: this.props.album ? this.props.album.cover : '',
		year: this.props.album ? this.props.album.year : '',
		band_id: this.props.match.params.id, //this.props.album ? this.props.album.band_id : '',
		errors: {},
		loading: false,
		ratingSelected: false,
		//redirect: false,
		captcha: captcha,
		captchaError: '',
		captchaInput: '',
		showBanner: false,
	  };
	}



	componentWillReceiveProps = (nextProps) => {
		this.setState({
			id: nextProps.id,
			title: nextProps.title,
			description: nextProps.description,
			rating: nextProps.rating,
			siisti: nextProps.siisti,
			helppo: nextProps.helppo,
			cover: nextProps.cover,
			year: nextProps.year,
			band_id: nextProps.band_id
		})
	}

	handleChange = (e) => {
		if (e.target.name === 'rating') {
			this.setState({ ratingSelected: true });
		  }
		this.setState({ [e.target.name]: e.target.value });
	}

	handleSubmit = (e) => {

		e.preventDefault();
		let errors = {};

		// Validation
		if (this.state.title === '') errors.title = "Täytä tämä kohta.";
		if (this.state.description === '') errors.description = "Täytä tämä kohta.";
		//if (this.state.year === '') errors.year = "This field can't be empty";
		//if (this.state.cover === '') errors.cover = "This field can't be empty";
		if (!this.validateCaptcha()) {
			this.setState({ captchaError: 'Laskutoimitus väärin.' });
			return;
		  } else {
			this.setState({ captchaError: '' });
		  }
		// Fill the errors object state
		this.setState({ errors });

		// Proceed if everything is OK
		if (Object.keys(errors).length === 0) {
			this.setState({ loading: true }); // Set up the loading
			const { id, title, year, description, rating, cover, siisti, helppo, band_id } = this.state;
			const successCallback = () => {
				this.setState({ showBanner: true });
				setTimeout(() => {
				  this.setState({ showBanner: false });
				  this.props.history.push(`/vessa/${this.props.match.params.id}/arvostelut`);
				}, 2000);
			  };
		  
			if (id && id > 0) {
			  this.props.updateAlbum({ id, title, year, description, rating, cover, siisti, helppo, band_id }).then(successCallback);
			} else {
			  this.props.addAlbum({ title, year, description, cover, rating, siisti, helppo, band_id }).then(successCallback);
			  console.log("Submitting data:", { title, year, description, cover, rating, siisti, helppo, band_id });
			}
		  }
	}

	getRatingText() {
		const { rating } = this.state;
		if (rating >= 1 && rating <= 3) {
		  return "'Todella huono'";
		} else if (rating >= 4 && rating <= 6) {
		  return "'Ihan OK'";
		} else if (rating >= 7) {
		  return "'Erittäin hyvä!'";
		}
	  }

	  handleSiistiChange = (e) => {
		this.setState({ siisti: parseInt(e.target.value) });
	  };

	  handleHelppoChange = (e) => {
		this.setState({ helppo: parseInt(e.target.value) });
	  };

	  generateCaptcha() {
		const num1 = Math.floor(Math.random() * 10);
		const num2 = Math.floor(Math.random() * 10);
		return `${num1} + ${num2}`;
	  }
	  handleCaptchaChange = (e) => {
		this.setState({ captchaInput: e.target.value });
	  };
		
	  validateCaptcha() {
		const [num1, , num2] = this.state.captcha.split(' ');
		const sum = parseInt(num1) + parseInt(num2);
		return parseInt(this.state.captchaInput) === sum;
	  }

	render() {
		return (
			<div>
				{this.state.showBanner && (
        		<div className="ui positive message">
          		<p>Arvostelu lisätty!</p>
        	</div>
      			)}

				{
					// Redirect if some action has worked succesfully, render if not
/* 					this.state.redirect ?
						<Redirect to={`/vessa/${this.props.match.params.id}/arvostelut`} /> : */
						<div className="ui container">
							
							<NavLink exact to={`/vessa/${this.props.match.params.id}/arvostelut`} className="ui button blue"><i className="icon-tasks"></i> Takaisin arvosteluihin</NavLink>
							
							<h3>Lisää arvostelusi vessasta</h3>
							<form className={classnames('ui', 'form', { 'loading': this.state.loading })} onSubmit={this.handleSubmit}>
								{/* <h4 className="ui dividing header">Lisää arvostelusi vessasta:</h4> */}

								{!!this.state.errors.global && <div className="ui negative message"><p>{this.state.errors.global}</p></div>}

								<div className={classnames("field", { error: !!this.state.errors.title })}>
									<label htmlFor="title">Nimimerkki</label>
									<input
										id="title" name="title"
										value={this.state.title}
										className="ui input"
										maxLength={30}
										placeholder="Esim. 'Raimo'"
										onChange={this.handleChange}
									/>
									<span>{this.state.errors.title}</span>
								</div>

{/* 								<div className={classnames("field", { error: !!this.state.errors.year })}>
									<label htmlFor="year">Year</label>
									<input
										id="year" name="year"
										value={this.state.year}
										className="ui input"
										placeholder="The album year"
										onChange={this.handleChange}
									/>
									<span>{this.state.errors.title}</span>
								</div> */}


{/* 								<div>
									<input
									type="checkbox" id="paperia" name="paperia"
									checked={this.state.paperia}
									className="ui input"
									onChange={this.handleChange}
									/>
									<label htmlFor="paperia" style={{ marginRight: '0.8rem' }}>&nbsp;&nbsp;Vessapaperia?</label>
								</div>
								<div>
									<input
									type="checkbox" id="helppokulku" name="helppokulku"
									checked={this.state.helppokulku}
									className="ui input"
									onChange={this.handleChange}
									/>
									<label htmlFor="helppokulku" style={{ marginRight: '0.8rem' }}>&nbsp;&nbsp;Helppokulkuinen?</label>
								</div> */}
								

								<div className={classnames("field", { error: !!this.state.errors.description })}>
									<label htmlFor="description">Arvostelu</label>
									<textarea
										id="description" name="description"
										value={this.state.description}
										className="ui input"
										maxLength={500}
										placeholder="Esim. 'Vessa oli todella siisti ja helppo käydä vauvan kanssa!'"
										onChange={this.handleChange}
										style={{height: '115px'}}
									></textarea>
									<span>{this.state.errors.description}</span>
								</div>

								<div className="field">
								<label htmlFor="siisti">Oliko vessa siisti?</label>
								<select
									id="siisti"
									name="siisti"
									value={this.state.siisti}
									className="ui dropdown"
									onChange={this.handleSiistiChange}
								>
									<option value={0}>En osaa sanoa</option>
									<option value={1}>Ei</option>
									<option value={2}>Kyllä</option>
								</select>
								</div>


								<div className="field">
								<label htmlFor="helppo">Oliko vessa helppokulkuinen?</label>
								<select
									id="helppo"
									name="helppo"
									value={this.state.helppo}
									className="ui dropdown"
									onChange={this.handleHelppoChange}
								>
									<option value={0}>En osaa sanoa</option>
									<option value={1}>Ei</option>
									<option value={2}>Kyllä</option>
								</select>
								</div>


								<div className={classnames("field", { error: !!this.state.errors.rating })}>
									<label htmlFor="rating">Yleisarvosana: {this.state.rating}</label>
									<input
										type="range" id="rating" name="rating" min="0" max="10"
										value={this.state.rating}
										className="ui input"
										disabled={!!this.state.id}
										onChange={this.handleChange}
									/>
									{!this.state.ratingSelected && this.state.rating === 0 && (
										<div className="ui pointing red basic label">Ei asetettu</div>
									)}
									  <div>{this.getRatingText()}</div>
									<span>{this.state.errors.rating}</span>
									</div>







									<div className={classnames("field", { error: !!this.state.captchaError })}>
									<label htmlFor="captchaInput">{this.state.captcha} = ?</label>
									<input
										id="captchaInput"
										name="captchaInput"
										value={this.state.captchaInput}
										className="ui input"
										placeholder="Ethän ole robotti; anna yllä olevan laskun vastaus."
										onChange={this.handleCaptchaChange}
									/>
									<span>{this.state.captchaError}</span>
									</div>


{/* 								<div className={classnames("field", { error: !!this.state.errors.cover })}>
									<label htmlFor="cover">Cover URL</label>
									<input
										id="cover" name="cover"
										value={this.state.cover}
										className="ui input"
										placeholder="The album cover URL"
										onChange={this.handleChange}
									/>
									<span>{this.state.errors.title}</span>
								</div>
 */}
								<div className="field">
									{this.state.cover !== '' && <img src={this.state.cover} alt="cover" className="ui small bordered image" />}
								</div>

								<div className="field">
									<button type="submit" className="ui button green"><i className="icon-save"></i> Lisää arvostelu</button>
								</div>
							</form>
						</div>
				}
			</div>
		)
	}
}

AlbumForm.propTypes = {
	updateAlbum: PropTypes.func.isRequired,
	addAlbum: PropTypes.func.isRequired
};

function mapStateToProps(state, props) {
	if (props.match.params && props.match.params.id > 0) {
		const band = state.bands.find(item => item.id == props.match.params.id);
		const album = band ? band.albums.find(item => item.id == props.match.params.album_id) : null;
		return { album: album };
	}

	return { album: null };
}

export default connect(mapStateToProps, { addAlbum, updateAlbum })(AlbumForm);
