import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { withRouter } from 'react-router-dom';

const API_KEY = 'AIzaSyDEVc7GnngMF6UeRXd3m9Nqsf58eYWXHkg';
const api_map_url = process.env.REACT_APP_API_BANDS_URL;
const MapWithMarkers = (props) => {
  const [locations, setLocations] = useState([]);

  const containerStyle = {
    width: '100%',
    height: '400px',
  };

  // Center the map on Finland
  const center = {
    lat: 63.9631,
    lng: 25.0680,
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(api_map_url);
      const locationsData = response.data.bands.map(({ id, lat, lng }) => ({ id, lat, lng }));

      setLocations(locationsData);
    };

    fetchData();
  }, []);

  const onMarkerClick = (id) => {
    props.history.push(`/vessa/${id}`);
  };

  return (
    <LoadScript googleMapsApiKey={API_KEY}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={5} // Adjust the zoom level to better display Finland
      >
        {locations.map((location) => (
          <Marker
            key={location.id}
            position={{ lat: parseFloat(location.lat), lng: parseFloat(location.lng) }}
            onClick={() => onMarkerClick(location.id)}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default withRouter(MapWithMarkers);
