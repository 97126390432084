import axios from 'axios';

// -----------------------------------------------------------
// Action types
// -----------------------------------------------------------
export const FEEDBACK_SAVED = 'FEEDBACK_SAVED';
export const FEEDBACK_UPDATED = 'FEEDBACK_UPDATED';


// -----------------------------------------------------------
// Actions to be dispatched to the reducer
// -----------------------------------------------------------
export function feedbackSaved(feedback) {
	return { type: FEEDBACK_SAVED, feedback };
}
export function feedbackUpdated(feedback) {
	return { type: FEEDBACK_UPDATED, feedback };
}

// -----------------------------------------------------------
// Business rules before dispatch
// -----------------------------------------------------------
export function addFeedback(feedback) {
	return dispatch => {
		const {id, description } = feedback;
		return axios.post('/api/feedbacks', { description })
			.then(response => {console.log(response); dispatch(feedbackSaved(response.data.feedback))})
			.catch(error => console.log(error));
	}
}
export function updateFeedback(feedback) {
	return dispatch => {
		const {id, description } = feedback;
		return axios.put(`/api/feedbacks/${id}`, { id, description })
			.then(response => dispatch(feedbackUpdated(feedback)))
			.catch(error => console.log(error));
	}
}
