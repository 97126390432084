import React from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import Coffee from './Coffee';
import { Icon } from 'semantic-ui-react'
import logo from './vessa2.png';
class HomePage extends React.Component {
	render() {
		return (
			<div>
			<div className="ui container text">		
			<div className="success">
        	<p>
			
          <strong> <i className="icon-bullhorn"></i> </strong>
         9.5.2023 - Voit nyt lähettää <a href = "/muuta">palautetta tai kehitysehdotuksia</a>.
       		</p>
{/* 			<p>
          <strong> <i className="icon-bullhorn"></i> </strong>
         16.4.2023 - Arvostelumahdollisuus lisätty.
       		</p> */}
      </div>
					{/* <h3 className="ui header" align='center'><strong> <Icon fitted name='map marker alternate' /></strong> Tervetuloa VessaVahtiin <strong> <Icon fitted name='map marker alternate' /></strong></h3> */}
					<p align="center"><img src={logo} height={"60"} /></p>
					<b>Mikä?</b>

					<p>Kyseessä on palvelu, johon käyttäjät voivat ilmoittaa julkisia vessoja sekä niihin liityviä tietoja. Muut käyttäjät voivat arvostella vessan ja tarjota ajantasaisia lisätietoja.</p>

					<b>Miksi?</b>
					<p>Varsinkin vieraassa kaupungissa liikkuessa voi olla hankalaa löytää julkista, siistiä ja esteetöntä vessaa vieläpä vauvanhoitomahdollisuudella. Tähän ongelmaan Vessavahti tuo ratkaisun!</p>
					
					<b>Kenelle?</b>
					<p>Palvelu on aivan kaikille! Palvelun kautta voit löytää esim. tiedon, soveltuuko vessa lapsenhoitoon tai invalideille.</p>

				</div>

				<br />
				<br />

				<div class="ui vertical masthead center aligned segment">
					<div class="ui text container">
						<p>Jos pidät palvelusta...</p>
						{/* <h3 class="ui inverted header">Check the GitHub!</h3> */}
						<Coffee />
					</div>
				</div>
			</div>
		);
	}
}

export default HomePage;
