import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Redirect, NavLink } from 'react-router-dom';
import { fetchBand, updateBand, addBand } from '../../actions/bands';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
/**
 * @description Band form used to add and edit. We bind state and events binding to the component by constructor this time.
 * @extends React
 */

const containerStyle = {
	width: '100%',
	height: '400px',
  };
  
  const defaultCenter = {
	lat: 62.24147,
	lng: 25.72088,
  };
  
  const finlandBoundingBox = {
	north: 70.089157,
	south: 59.693242,
	west: 19.083209,
	east: 31.586958,
  };

 
class BandForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			id: '',
			title: '',
			year: 0,
			rating: 0,
			ratingSelected: false,
			genre: '',
			description: '',
			lat: 0,
			lng: 0,
			esteeton: 0,
			ilmainen: 0,
			vauva: 0,
			address: '',
			markerPosition: null,
			mapCenter: null,
			errors: {},
			loading: false,
			redirect: false,
			showBanner: false,
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);

	}

	componentDidMount() {
		if (this.props.match && this.props.match.params && typeof this.props.match.params.id !== "undefined") {
			this.props.fetchBand(this.props.match.params.id);
		}
		this.setState({
			id: (this.props.band) ? this.props.band.id : '',
			title: (this.props.band) ? this.props.band.title : '',
			//year: (this.props.band) ? this.props.band.year : '',
			description: (this.props.description) ? this.props.band.description : ''
			
		});
	}

	componentWillReceiveProps = (nextProps) => {
		this.setState({
			id: nextProps.band.id,
			title: nextProps.band.title,
			year: nextProps.band.year,
			rating: nextProps.band.rating || 0,
			genre: nextProps.band.genre,
			description: nextProps.band.description,
			lat: nextProps.band.lat,
			lng: nextProps.band.lng,
			esteeton: nextProps.band.esteeton,
			ilmainen: nextProps.band.ilmainen,
			vauva: nextProps.band.vauva,
			address: nextProps.band.address,
			markerPosition: nextProps.band.lat && nextProps.band.lng ? { lat: nextProps.band.lat, lng: nextProps.band.lng } : null,
	});
	}
	isLocationWithinFinland(lat, lng) {
		const { north, south, west, east } = finlandBoundingBox;
		return lat >= south && lat <= north && lng >= west && lng <= east;
	  }
	
	handleChange(e) {
		if (e.target.name === 'rating') {
		  this.setState({ ratingSelected: true });
		}
		this.setState({ [e.target.name]: e.target.value });

		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
	  
		this.setState({ [name]: value });
	  }

	handleSubmit(e) {
		e.preventDefault();
		let errors = {};

		// Validation
		if (this.state.title === '') errors.title = "Täytä tämä kohta.";
		//if (this.state.year === '') errors.year = "Täytä tämä tieto";
		//if (this.state.rating === '') errors.rating = "Täytä tämä tieto";
		if (this.state.genre === '') errors.genre = "Täytä tämä kohta.";
		if (this.state.description === '') errors.description = "Täytä tämä kohta.";


		// Fill the errors object state
		this.setState({ errors });

		// Proceed if everything is OK
		if (Object.keys(errors).length === 0) {
			this.setState({ loading: true }); // Set up the loading
			const { id, title, year, rating, genre, description, lat, lng, esteeton, ilmainen, vauva, address } = this.state;
			const successCallback = () => {
				this.setState({ showBanner: true });
				setTimeout(() => {
				  this.setState({ showBanner: false });
				  this.props.history.push(`/vessa/${this.props.match.params.id}/arvostelut`);
				}, 2000);
			  };
		  
			if (id && id > 0) {
			  this.props.updateBand({ id, title, year, rating, genre, description, lat, lng, esteeton, ilmainen, vauva, address }).then(successCallback);
			} else {
			  this.props.addBand({ title, year, rating, genre, description, lat, lng, esteeton, ilmainen, vauva, address }).then(successCallback);
			  console.log("Submitting data:", { title, year, rating, genre, description, lat, lng, esteeton, ilmainen, vauva, address });
			}
		  }
		}

		handleMapClick(e) {
			const lat = e.latLng.lat();
			const lng = e.latLng.lng();
		  
			if (this.isLocationWithinFinland(lat, lng)) {
			  this.setState({
				markerPosition: {
				  lat: lat,
				  lng: lng,
				},
				lat: lat,
				lng: lng,
			  });
			} else {
			  alert('Aseta sijanti Suomen rajojen sisälle!');
			}
		  }
		  
	  
		handleChangeDate(e) {
			const date = new Date(e.target.value);
			this.setState({
			  selectedDate: date,
			  year: date.getFullYear(),
			});
		  }


	  getRatingText() {
		const { rating } = this.state;
		if (rating >= 1 && rating <= 3) {
		  return "'Todella likainen'";
		} else if (rating >= 4 && rating <= 6) {
		  return "'Hieman epäsiisti'";
		} else if (rating >= 7) {
		  return "'Todella siisti!'";
		}
	  }

	render() {
		return (
			<div>
				{this.state.showBanner && (
        		<div className="ui positive message">
          		<p>Uusi vessa lisätty!</p>
        	</div>
      			)}
				
				{
						<div className="ui container">
							<NavLink exact to="/vessat/" className="ui button blue"> <i className="icon-tasks"></i> Takaisin vessoihin</NavLink>
							{!this.state.id ? '' : <NavLink exact to={`/vessa/${this.state.id}/arvostelut`} className="ui button green"><i className="icon discussions"></i> Arvostelut</NavLink>}
							{/* <h3>{!this.state.id ? 'Lisää uusi vessa' : this.props.band.title}</h3> */}
							<br />	<br />
							<form className={classnames("ui", "form", { loading: this.state.loading })} onSubmit={this.handleSubmit}>

								<h3>{!this.state.id ? 'Lisää uusi vessa' : 'Vessan tiedot'}</h3>

								{!!this.state.errors.global && <div className="ui negative message"><p>{this.state.errors.global}</p></div>}



								<div className={classnames("field", { error: !!this.state.errors.genre })}>
								<label for="genre">Paikkakunta</label>
								<select name="genre" id="genre" onChange={this.handleChange} value={this.state.genre}  disabled={!!this.state.id}
								style={{
								color: this.state.id ? 'inherit' : null,
								opacity: this.state.id ? 1 : null,
								}}>
									<option value="">-- Valitse paikkakunta --</option>
									<option value= "Akaa">Akaa</option>
<option value= "Alajärvi">Alajärvi</option>
<option value= "Alavieska">Alavieska</option>
<option value= "Alavus">Alavus</option>
<option value= "Asikkala">Asikkala</option>
<option value= "Askola">Askola</option>
<option value= "Aura">Aura</option>
<option value= "Brändö">Brändö</option>
<option value= "Eckerö">Eckerö</option>
<option value= "Enonkoski">Enonkoski</option>
<option value= "Enontekiö">Enontekiö</option>
<option value= "Espoo">Espoo</option>
<option value= "Eura">Eura</option>
<option value= "Eurajoki">Eurajoki</option>
<option value= "Evijärvi">Evijärvi</option>
<option value= "Finström">Finström</option>
<option value= "Forssa">Forssa</option>
<option value= "Föglö">Föglö</option>
<option value= "Geta">Geta</option>
<option value= "Haapajärvi">Haapajärvi</option>
<option value= "Haapavesi">Haapavesi</option>
<option value= "Hailuoto">Hailuoto</option>
<option value= "Halsua">Halsua</option>
<option value= "Hamina">Hamina</option>
<option value= "Hammarland">Hammarland</option>
<option value= "Hankasalmi">Hankasalmi</option>
<option value= "Hanko">Hanko</option>
<option value= "Harjavalta">Harjavalta</option>
<option value= "Hartola">Hartola</option>
<option value= "Hattula">Hattula</option>
<option value= "Hausjärvi">Hausjärvi</option>
<option value= "Heinola">Heinola</option>
<option value= "Heinävesi">Heinävesi</option>
<option value= "Helsinki">Helsinki</option>
<option value= "Hirvensalmi">Hirvensalmi</option>
<option value= "Hollola">Hollola</option>
<option value= "Huittinen">Huittinen</option>
<option value= "Humppila">Humppila</option>
<option value= "Hyrynsalmi">Hyrynsalmi</option>
<option value= "Hyvinkää">Hyvinkää</option>
<option value= "Hämeenkyrö">Hämeenkyrö</option>
<option value= "Hämeenlinna">Hämeenlinna</option>
<option value= "Ii">Ii</option>
<option value= "Iisalmi">Iisalmi</option>
<option value= "Iitti">Iitti</option>
<option value= "Ikaalinen">Ikaalinen</option>
<option value= "Ilmajoki">Ilmajoki</option>
<option value= "Ilomantsi">Ilomantsi</option>
<option value= "Imatra">Imatra</option>
<option value= "Inari">Inari</option>
<option value= "Inkoo">Inkoo</option>
<option value= "Isojoki">Isojoki</option>
<option value= "Isokyrö">Isokyrö</option>
<option value= "Janakkala">Janakkala</option>
<option value= "Joensuu">Joensuu</option>
<option value= "Jokioinen">Jokioinen</option>
<option value= "Jomala">Jomala</option>
<option value= "Joroinen">Joroinen</option>
<option value= "Joutsa">Joutsa</option>
<option value= "Juuka">Juuka</option>
<option value= "Juupajoki">Juupajoki</option>
<option value= "Juva">Juva</option>
<option value= "Jyväskylä">Jyväskylä</option>
<option value= "Jämijärvi">Jämijärvi</option>
<option value= "Jämsä">Jämsä</option>
<option value= "Järvenpää">Järvenpää</option>
<option value= "Kaarina">Kaarina</option>
<option value= "Kaavi">Kaavi</option>
<option value= "Kajaani">Kajaani</option>
<option value= "Kalajoki">Kalajoki</option>
<option value= "Kangasala">Kangasala</option>
<option value= "Kangasniemi">Kangasniemi</option>
<option value= "Kankaanpää">Kankaanpää</option>
<option value= "Kannonkoski">Kannonkoski</option>
<option value= "Kannus">Kannus</option>
<option value= "Karijoki">Karijoki</option>
<option value= "Karkkila">Karkkila</option>
<option value= "Karstula">Karstula</option>
<option value= "Karvia">Karvia</option>
<option value= "Kaskinen">Kaskinen</option>
<option value= "Kauhajoki">Kauhajoki</option>
<option value= "Kauhava">Kauhava</option>
<option value= "Kauniainen">Kauniainen</option>
<option value= "Kaustinen">Kaustinen</option>
<option value= "Keitele">Keitele</option>
<option value= "Kemi">Kemi</option>
<option value= "Kemijärvi">Kemijärvi</option>
<option value= "Keminmaa">Keminmaa</option>
<option value= "Kemiönsaari">Kemiönsaari</option>
<option value= "Kempele">Kempele</option>
<option value= "Kerava">Kerava</option>
<option value= "Keuruu">Keuruu</option>
<option value= "Kihniö">Kihniö</option>
<option value= "Kinnula">Kinnula</option>
<option value= "Kirkkonummi">Kirkkonummi</option>
<option value= "Kitee">Kitee</option>
<option value= "Kittilä">Kittilä</option>
<option value= "Kiuruvesi">Kiuruvesi</option>
<option value= "Kivijärvi">Kivijärvi</option>
<option value= "Kokemäki">Kokemäki</option>
<option value= "Kokkola">Kokkola</option>
<option value= "Kolari">Kolari</option>
<option value= "Konnevesi">Konnevesi</option>
<option value= "Kontiolahti">Kontiolahti</option>
<option value= "Korsnäs">Korsnäs</option>
<option value= "Koski Tl">Koski Tl</option>
<option value= "Kotka">Kotka</option>
<option value= "Kouvola">Kouvola</option>
<option value= "Kristiinankaupunki">Kristiinankaupunki</option>
<option value= "Kruunupyy">Kruunupyy</option>
<option value= "Kuhmo">Kuhmo</option>
<option value= "Kuhmoinen">Kuhmoinen</option>
<option value= "Kumlinge">Kumlinge</option>
<option value= "Kuopio">Kuopio</option>
<option value= "Kuortane">Kuortane</option>
<option value= "Kurikka">Kurikka</option>
<option value= "Kustavi">Kustavi</option>
<option value= "Kuusamo">Kuusamo</option>
<option value= "Kyyjärvi">Kyyjärvi</option>
<option value= "Kärkölä">Kärkölä</option>
<option value= "Kärsämäki">Kärsämäki</option>
<option value= "Kökar">Kökar</option>
<option value= "Lahti">Lahti</option>
<option value= "Laihia">Laihia</option>
<option value= "Laitila">Laitila</option>
<option value= "Lapinjärvi">Lapinjärvi</option>
<option value= "Lapinlahti">Lapinlahti</option>
<option value= "Lappajärvi">Lappajärvi</option>
<option value= "Lappeenranta">Lappeenranta</option>
<option value= "Lapua">Lapua</option>
<option value= "Laukaa">Laukaa</option>
<option value= "Lemi">Lemi</option>
<option value= "Lemland">Lemland</option>
<option value= "Lempäälä">Lempäälä</option>
<option value= "Leppävirta">Leppävirta</option>
<option value= "Lestijärvi">Lestijärvi</option>
<option value= "Lieksa">Lieksa</option>
<option value= "Lieto">Lieto</option>
<option value= "Liminka">Liminka</option>
<option value= "Liperi">Liperi</option>
<option value= "Lohja">Lohja</option>
<option value= "Loimaa">Loimaa</option>
<option value= "Loppi">Loppi</option>
<option value= "Loviisa">Loviisa</option>
<option value= "Luhanka">Luhanka</option>
<option value= "Lumijoki">Lumijoki</option>
<option value= "Lumparland">Lumparland</option>
<option value= "Luoto">Luoto</option>
<option value= "Luumäki">Luumäki</option>
<option value= "Maalahti">Maalahti</option>
<option value= "Maarianhamina – Mariehamn">Maarianhamina – Mariehamn</option>
<option value= "Marttila">Marttila</option>
<option value= "Masku">Masku</option>
<option value= "Merijärvi">Merijärvi</option>
<option value= "Merikarvia">Merikarvia</option>
<option value= "Miehikkälä">Miehikkälä</option>
<option value= "Mikkeli">Mikkeli</option>
<option value= "Muhos">Muhos</option>
<option value= "Multia">Multia</option>
<option value= "Muonio">Muonio</option>
<option value= "Mustasaari">Mustasaari</option>
<option value= "Muurame">Muurame</option>
<option value= "Mynämäki">Mynämäki</option>
<option value= "Myrskylä">Myrskylä</option>
<option value= "Mäntsälä">Mäntsälä</option>
<option value= "Mänttä-Vilppula">Mänttä-Vilppula</option>
<option value= "Mäntyharju">Mäntyharju</option>
<option value= "Naantali">Naantali</option>
<option value= "Nakkila">Nakkila</option>
<option value= "Nivala">Nivala</option>
<option value= "Nokia">Nokia</option>
<option value= "Nousiainen">Nousiainen</option>
<option value= "Nurmes">Nurmes</option>
<option value= "Nurmijärvi">Nurmijärvi</option>
<option value= "Närpiö">Närpiö</option>
<option value= "Orimattila">Orimattila</option>
<option value= "Oripää">Oripää</option>
<option value= "Orivesi">Orivesi</option>
<option value= "Oulainen">Oulainen</option>
<option value= "Oulu">Oulu</option>
<option value= "Outokumpu">Outokumpu</option>
<option value= "Padasjoki">Padasjoki</option>
<option value= "Paimio">Paimio</option>
<option value= "Paltamo">Paltamo</option>
<option value= "Parainen">Parainen</option>
<option value= "Parikkala">Parikkala</option>
<option value= "Parkano">Parkano</option>
<option value= "Pedersöre">Pedersöre</option>
<option value= "Pelkosenniemi">Pelkosenniemi</option>
<option value= "Pello">Pello</option>
<option value= "Perho">Perho</option>
<option value= "Pertunmaa">Pertunmaa</option>
<option value= "Petäjävesi">Petäjävesi</option>
<option value= "Pieksämäki">Pieksämäki</option>
<option value= "Pielavesi">Pielavesi</option>
<option value= "Pietarsaari">Pietarsaari</option>
<option value= "Pihtipudas">Pihtipudas</option>
<option value= "Pirkkala">Pirkkala</option>
<option value= "Polvijärvi">Polvijärvi</option>
<option value= "Pomarkku">Pomarkku</option>
<option value= "Pori">Pori</option>
<option value= "Pornainen">Pornainen</option>
<option value= "Porvoo">Porvoo</option>
<option value= "Posio">Posio</option>
<option value= "Pudasjärvi">Pudasjärvi</option>
<option value= "Pukkila">Pukkila</option>
<option value= "Punkalaidun">Punkalaidun</option>
<option value= "Puolanka">Puolanka</option>
<option value= "Puumala">Puumala</option>
<option value= "Pyhtää">Pyhtää</option>
<option value= "Pyhäjoki">Pyhäjoki</option>
<option value= "Pyhäjärvi">Pyhäjärvi</option>
<option value= "Pyhäntä">Pyhäntä</option>
<option value= "Pyhäranta">Pyhäranta</option>
<option value= "Pälkäne">Pälkäne</option>
<option value= "Pöytyä">Pöytyä</option>
<option value= "Raahe">Raahe</option>
<option value= "Raasepori">Raasepori</option>
<option value= "Raisio">Raisio</option>
<option value= "Rantasalmi">Rantasalmi</option>
<option value= "Ranua">Ranua</option>
<option value= "Rauma">Rauma</option>
<option value= "Rautalampi">Rautalampi</option>
<option value= "Rautavaara">Rautavaara</option>
<option value= "Rautjärvi">Rautjärvi</option>
<option value= "Reisjärvi">Reisjärvi</option>
<option value= "Riihimäki">Riihimäki</option>
<option value= "Ristijärvi">Ristijärvi</option>
<option value= "Rovaniemi">Rovaniemi</option>
<option value= "Ruokolahti">Ruokolahti</option>
<option value= "Ruovesi">Ruovesi</option>
<option value= "Rusko">Rusko</option>
<option value= "Rääkkylä">Rääkkylä</option>
<option value= "Saarijärvi">Saarijärvi</option>
<option value= "Salla">Salla</option>
<option value= "Salo">Salo</option>
<option value= "Saltvik">Saltvik</option>
<option value= "Sastamala">Sastamala</option>
<option value= "Sauvo">Sauvo</option>
<option value= "Savitaipale">Savitaipale</option>
<option value= "Savonlinna">Savonlinna</option>
<option value= "Savukoski">Savukoski</option>
<option value= "Seinäjoki">Seinäjoki</option>
<option value= "Sievi">Sievi</option>
<option value= "Siikainen">Siikainen</option>
<option value= "Siikajoki">Siikajoki</option>
<option value= "Siikalatva">Siikalatva</option>
<option value= "Siilinjärvi">Siilinjärvi</option>
<option value= "Simo">Simo</option>
<option value= "Sipoo">Sipoo</option>
<option value= "Siuntio">Siuntio</option>
<option value= "Sodankylä">Sodankylä</option>
<option value= "Soini">Soini</option>
<option value= "Somero">Somero</option>
<option value= "Sonkajärvi">Sonkajärvi</option>
<option value= "Sotkamo">Sotkamo</option>
<option value= "Sottunga">Sottunga</option>
<option value= "Sulkava">Sulkava</option>
<option value= "Sund">Sund</option>
<option value= "Suomussalmi">Suomussalmi</option>
<option value= "Suonenjoki">Suonenjoki</option>
<option value= "Sysmä">Sysmä</option>
<option value= "Säkylä">Säkylä</option>
<option value= "Taipalsaari">Taipalsaari</option>
<option value= "Taivalkoski">Taivalkoski</option>
<option value= "Taivassalo">Taivassalo</option>
<option value= "Tammela">Tammela</option>
<option value= "Tampere">Tampere</option>
<option value= "Tervo">Tervo</option>
<option value= "Tervola">Tervola</option>
<option value= "Teuva">Teuva</option>
<option value= "Tohmajärvi">Tohmajärvi</option>
<option value= "Toholampi">Toholampi</option>
<option value= "Toivakka">Toivakka</option>
<option value= "Tornio">Tornio</option>
<option value= "Turku">Turku</option>
<option value= "Tuusniemi">Tuusniemi</option>
<option value= "Tuusula">Tuusula</option>
<option value= "Tyrnävä">Tyrnävä</option>
<option value= "Ulvila">Ulvila</option>
<option value= "Urjala">Urjala</option>
<option value= "Utajärvi">Utajärvi</option>
<option value= "Utsjoki">Utsjoki</option>
<option value= "Uurainen">Uurainen</option>
<option value= "Uusikaarlepyy">Uusikaarlepyy</option>
<option value= "Uusikaupunki">Uusikaupunki</option>
<option value= "Vaala">Vaala</option>
<option value= "Vaasa">Vaasa</option>
<option value= "Valkeakoski">Valkeakoski</option>
<option value= "Vantaa">Vantaa</option>
<option value= "Varkaus">Varkaus</option>
<option value= "Vehmaa">Vehmaa</option>
<option value= "Vesanto">Vesanto</option>
<option value= "Vesilahti">Vesilahti</option>
<option value= "Veteli">Veteli</option>
<option value= "Vieremä">Vieremä</option>
<option value= "Vihti">Vihti</option>
<option value= "Viitasaari">Viitasaari</option>
<option value= "Vimpeli">Vimpeli</option>
<option value= "Virolahti">Virolahti</option>
<option value= "Virrat">Virrat</option>
<option value= "Vårdö">Vårdö</option>
<option value= "Vöyri">Vöyri</option>
<option value= "Ylitornio">Ylitornio</option>
<option value= "Ylivieska">Ylivieska</option>
<option value= "Ylöjärvi">Ylöjärvi</option>
<option value= "Ypäjä">Ypäjä</option>
<option value= "Ähtäri">Ähtäri</option>
<option value= "Äänekoski">Äänekoski</option>

								</select>
								<span>{this.state.errors.genre}</span>
									</div>



								<div className={classnames("field", { error: !!this.state.errors.title })}>
									<label htmlFor="title">Vessan nimi</label>
									<input
										type="text" id="title" name="title"
										value={this.state.title}
										className="ui input"
										maxLength={40}
										placeholder="Esim. 'Kauppakeskus Aarian vessa'"
										onChange={this.handleChange}
										disabled={!!this.state.id}
										style={{
											color: this.state.id ? 'inherit' : null,
											opacity: this.state.id ? 1 : null,
										  }}
									/>
									<span>{this.state.errors.title}</span>
								</div>


								<div className={classnames("field", { error: !!this.state.errors.address })}>
									<label htmlFor="address">Osoite</label>
									<input
										type="text" id="address" name="address"
										value={this.state.address}
										className="ui input"
										maxLength={100}
										placeholder="Esim. 'Paratiisikatu 14'"
										onChange={this.handleChange}
										disabled={!!this.state.id}
										style={{
											color: this.state.id ? 'inherit' : null,
											opacity: this.state.id ? 1 : null,
										  }}
									/>
									<span>{this.state.errors.address}</span>
								</div>


{/* 								<div className={classnames("field", { error: !!this.state.errors.rating })}>
									<label htmlFor="rating">Yleisarvosana: {this.state.rating}</label>
									<input
										type="range" id="rating" name="rating" min="0" max="10"
										value={this.state.rating}
										className="ui input"
										disabled={!!this.state.id}
										onChange={this.handleChange}
									/>
									{!this.state.ratingSelected && this.state.rating === 0 && (
										<div className="ui pointing red basic label">Ei asetettu</div>
									)}
									  <div>{this.getRatingText()}</div>
									<span>{this.state.errors.rating}</span>
									</div>
 */}
	

{/* 
								<div className={classnames("field", { error: !!this.state.errors.year })}>
								<label htmlFor="year">Käyntipäivä</label>
								<input
									type="date"
									id="year"
									name="year"
									disabled={!!this.state.id}
									value={this.state.selectedDate ? this.state.selectedDate.toISOString().split('T')[0] : ''}
									onChange={this.handleChangeDate}
									max={new Date().toISOString().split('T')[0]}
									style={{
										color: this.state.id ? 'inherit' : null,
										opacity: this.state.id ? 1 : null,
									  }}
								/>
								{this.state.selectedDate && (
									<div>
									<p>Selected date: {this.state.selectedDate.toISOString().replace('T', ' ').substring(0, 19)}</p>
									</div>
								)} 
								<span>{this.state.errors.year}</span>
								</div>
*/}

								{/* <div className={classnames("field", { error: !!this.state.errors.year })}>
									<label htmlFor="year">Year</label>
									<input
										type="number" id="year" name="year" 
										value={this.state.year}
										className="ui input"
										placeholder="Foundation year"
										onChange={this.handleChange}
										min="1900"
										max="2100"
									/>
									<span>{this.state.errors.year}</span>
								</div>
 								*/}

								<strong><label htmlFor="muuta">Muuta</label></strong>

										
								<div>							
									<input
									type="checkbox" id="ilmainen" name="ilmainen"
									checked={this.state.ilmainen}
									className="ui input"
									disabled={!!this.state.id}
									onChange={this.handleChange}
									/>
									<label htmlFor="ilmainen" style={{ marginRight: '0.8rem' }}><strong><i className="icon euro"></i></strong>Ilmainen?</label>
								</div>
								
								<div>	
									<input
									type="checkbox" id="esteeton" name="esteeton"
									checked={this.state.esteeton}
									className="ui input"
									disabled={!!this.state.id}
									onChange={this.handleChange}
									/>
									<label htmlFor="esteeton" style={{ marginRight: '0.8rem' }}><strong><i className="icon wheelchair"></i></strong>Myös inva-WC?</label>
								</div>

								<div>
									<input
									type="checkbox" id="vauva" name="vauva"
									checked={this.state.vauva}
									className="ui input"
									disabled={!!this.state.id}
									onChange={this.handleChange}
									/>
									<label htmlFor="vauva" style={{ marginRight: '0.8rem' }}><strong><i className="icon child"></i></strong>Vauvanhoitomahdollisuus?</label>
								</div>
								<br></br>
								<div className={classnames("field", { error: !!this.state.errors.description })}>
									<label htmlFor="description">Kuvaus/lisätietoja</label>
									<textarea
										id="description" name="description"
										className="ui input"
										maxLength={500}
										placeholder="Esim. 'Keskellä kauppakeskusta 2. kerroksessa, käynti etuoven puolelta, korkea kynnys, oven lukko rikki, erillinen puoli miehille ja naisille.'"
										onChange={this.handleChange}
										value={this.state.description}
										style={{
											height: '115px',
											color: this.state.id ? 'inherit' : null,
											opacity: this.state.id ? 1 : null,
										  }}
										  
										disabled={!!this.state.id}
									></textarea>
									<span>{this.state.errors.description}</span>
								</div>
	

								<div className={classnames("field", { error: !!this.state.errors.lat })}>
								<label htmlFor="lat">Sijainti kartalla</label>
								<LoadScript googleMapsApiKey="AIzaSyDEVc7GnngMF6UeRXd3m9Nqsf58eYWXHkg">
								<GoogleMap
								mapContainerStyle={containerStyle}
								center={this.state.markerPosition || this.state.mapCenter || defaultCenter}
								zoom={this.state.markerPosition ? 14 : 5}
								onClick={(e) => this.handleMapClick(e)}
								>
								{this.state.markerPosition && (
									<Marker position={this.state.markerPosition} />
								)}
								</GoogleMap>
								</LoadScript>
								{this.state.markerPosition && (
									<div hidden>
									<p>Latitude: {this.state.markerPosition.lat}</p>
									<p>Longitude: {this.state.markerPosition.lng}</p>
									</div>
								)}</div>


								<div className="field">
								<h1>{!this.state.id ? <button type="submit" className="ui button green"><i className="icon-save"></i> Lisää vessa</button> : ""}</h1>
									
								</div>
					
							</form>
						</div>
				}
			</div>
		);
	}
}

BandForm.propTypes = {
	band: PropTypes.object,
	fetchBand: PropTypes.func.isRequired,
	updateBand: PropTypes.func.isRequired,
	addBand: PropTypes.func.isRequired
};

function mapStateToProps(state, props) {
	if (props.match && props.match.params && props.match.params.id > 0) {
		return {
			band: state.bands.find(item => item.id == props.match.params.id)
		};
	}

	return { band: null };
}

export default connect(mapStateToProps, { fetchBand, updateBand, addBand })(BandForm);