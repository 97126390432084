import React from 'react';
import { Icon } from 'semantic-ui-react'
const NotFound = () => {
  return (
    <div>
      <h1>Sivua ei löytynyt <strong> <Icon fitted name='thumbs down outline' /></strong></h1>
      <p><a href="/">Siirry etusivulle</a></p>
    </div>
  );
};

export default NotFound;
