import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Icon, Dropdown } from 'semantic-ui-react';
import 'font-awesome/css/font-awesome.min.css';
import 'semantic-ui-css/semantic.min.css';

class Menu extends React.Component {
  render() {
    return (
      <div className="ui fixed inverted menu">
        <NavLink className="header item" activeonlywhenexact="active" exact to="/">
          <FontAwesomeIcon icon={icon({ name: 'toilet-paper' })} style={{ fontSize: 22 }} />
          &nbsp;&nbsp;VessaVahti
        </NavLink>
		<Dropdown item text={<><strong><i className="icon list"></i></strong> Vessat</>}>

          <Dropdown.Menu> 
            <Dropdown.Item as={NavLink} activeonlywhenexact="active" exact to="/vessat">
              <strong>
                <i className="icon list "></i>{' '}
              </strong>
              Vessat
            </Dropdown.Item>
            <Dropdown.Item as={NavLink} activeonlywhenexact="active" exact to="/vessat/uusi">
              <strong>
                <i className="icon add circle "></i>{' '}
              </strong>
              Lisää
            </Dropdown.Item>
            <Dropdown.Item as={NavLink} activeonlywhenexact="active" exact to="/kartta">
              <strong>
                <i className="icon map "></i>{' '}
              </strong>
              Kartta
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <NavLink className="item" activeonlywhenexact="active" exact to="/muuta">
          <strong>
            <i className="icon star "></i>{' '}
          </strong>
          Info
          {/* <Icon circular color="green" name='users' /> */}
        </NavLink>
      </div>
    );
  }
}

export default Menu;
