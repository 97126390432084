import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from '../components/Home/Home';
import Kartta from '../components/Home/Info';
import Muuta from '../components/Home/Misc';
import BandList from '../components/Band/BandList';
import Vessa from '../components/Band/Vessa';
import AlbumList from '../components/Band/Album/AlbumList';
import AlbumForm from '../components/Band/Album/AlbumForm';
import NotFound from '../components/NotFound';

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Kartta" component={Kartta} />
        <Route exact path="/Muuta" component={Muuta} />
        <Route exact path="/vessat" component={BandList} />
        <Route path="/vessat/uusi" component={Vessa} />
        <Route exact path="/vessa/:id" component={Vessa} />
        <Route exact path="/vessa/:id/arvostelut/" component={AlbumList} />
        <Route exact path="/vessa/:id/arvostelu/uusi" component={AlbumForm} />
        <Route exact path="/vessa/:id/arvostelu/:album_id" component={AlbumForm} />
        {/* Fallback route for non-existing URLs */}
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default Routes;
