import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Table, Pagination } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Select from 'react-select';
import axios from 'axios';

// Actions
import { fetchBands } from '../../actions/bands';
const api_url = process.env.REACT_APP_API_BANDS_URL;


class BandsListPage extends React.Component {
  state = {
    bands: [],
    selectedBand: '',
    filterByRating: false, 
    selectedGenre: '', 
    filterByWeek: false, 
    filterByEsteeton: false, 
    filterByIlmainen: false, 
    filterByLapsi: false, 
    currentPage: 1,
  };
  handlePaginationChange = (e, { activePage }) => {
    this.setState({ currentPage: activePage });
  };

  handleFilterChange = (event) => {
    this.setState({ filterByRating: event.target.checked });
  };

  handleGenreChange = (selectedOption) => {
    this.setState({ selectedGenre: selectedOption.value });
  };

  handleWeekFilterChange = (event) => {
    this.setState({ filterByWeek: event.target.checked });
  };

  handleEsteetonFilterChange = (event) => {
    this.setState({ filterByEsteeton: event.target.checked });
  };

  handleilmainenFilterChange = (event) => {
    this.setState({ filterByIlmainen: event.target.checked });
  };

  handleLapsiFilterChange = (event) => {
    this.setState({ filterByLapsi: event.target.checked });
  };

  componentDidMount() {
    this.props.fetchBands();
    this.fetchBands();
  }

  fetchBands = async () => {
    try {
      const response = await axios.get(api_url);
      const bandsData = response.data.bands.map((band) => ({
        value: band.id,
        label: band.title,
		url: `/vessa/${band.id}/arvostelut`,
      }));
      this.setState({ bands: bandsData });
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (selectedOption) => {
	this.setState({ selectedBand: selectedOption });
	this.props.history.push(selectedOption.url);
  };

  render() {
    const { bands } = this.props;
    let filteredBands = bands;

    if (this.state.filterByRating) {
      filteredBands = filteredBands.filter((band) => band.rating > 5);
    }

    if (this.state.filterByEsteeton) {
      filteredBands = filteredBands.filter((band) => band.esteeton > 0);
    }

    if (this.state.filterByIlmainen) {
      filteredBands = filteredBands.filter((band) => band.ilmainen > 0);
    }

    if (this.state.filterByLapsi) {
      filteredBands = filteredBands.filter((band) => band.vauva > 0);
    }

    if (this.state.selectedGenre) {
      filteredBands = filteredBands.filter((band) => band.genre === this.state.selectedGenre);
    }

    // Filter bands created within last week if filterByWeek is true
    if (this.state.filterByWeek) {
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 4);
      filteredBands = filteredBands.filter(
        (band) => new Date(band.createdAt) >= oneWeekAgo
      );
    }
    const itemsPerPage = 10;
    const totalPages = Math.ceil(filteredBands.length / itemsPerPage);
    const startIndex = (this.state.currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
const gridLines = filteredBands.slice(startIndex, endIndex).map((band, i) => {
      const hasAlbums = band.albums.length > 0;
      return (
        <tr>
          <td style={{ textAlign: 'left' }}> <div>
          <NavLink to={`/vessa/${band.id}`} >
    {band.title}
          </NavLink>   
          </div>
            </td>
            <td style={{ textAlign: 'left' }}>
          
  <NavLink
    to={`/vessa/${band.id}/arvostelut/`}
    className={classnames({
      //disabled: !hasAlbums,
    })}
  >
    <div
      className={classnames('ui', 'label', 'circular', 'small', {
        green: hasAlbums,
        red: !hasAlbums,
      })}
    >
      {band.albums.length}
    </div>
  </NavLink>

          </td>
        </tr>
      );
    });

    const grid = (
      <>
        <Table celled striped selectable compact singleLine unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <div className="headerCellText">Vessa</div>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <div className="headerCellText">Arvostelut</div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{gridLines}</Table.Body>
        </Table>
        <Pagination
          activePage={this.state.currentPage}
          onPageChange={this.handlePaginationChange}
          totalPages={totalPages}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
        />
      </>
    );
    

    const emptyMessage = (
      <div className="ui info message">
        Ei yhtään kohdetta.
      </div>
    );

    const genreOptions = [
      { value: '', label: 'Valitse paikkakunta' },
      {value:'Akaa', label:'Akaa'}, 
      {value:'Alajärvi', label:'Alajärvi'}, 
      {value:'Alavieska', label:'Alavieska'}, 
      {value:'Alavus', label:'Alavus'}, 
      {value:'Asikkala', label:'Asikkala'}, 
      {value:'Askola', label:'Askola'}, 
      {value:'Aura', label:'Aura'}, 
      {value:'Brändö', label:'Brändö'}, 
      {value:'Eckerö', label:'Eckerö'}, 
      {value:'Enonkoski', label:'Enonkoski'}, 
      {value:'Enontekiö', label:'Enontekiö'}, 
      {value:'Espoo', label:'Espoo'}, 
      {value:'Eura', label:'Eura'}, 
      {value:'Eurajoki', label:'Eurajoki'}, 
      {value:'Evijärvi', label:'Evijärvi'}, 
      {value:'Finström', label:'Finström'}, 
      {value:'Forssa', label:'Forssa'}, 
      {value:'Föglö', label:'Föglö'}, 
      {value:'Geta', label:'Geta'}, 
      {value:'Haapajärvi', label:'Haapajärvi'}, 
      {value:'Haapavesi', label:'Haapavesi'}, 
      {value:'Hailuoto', label:'Hailuoto'}, 
      {value:'Halsua', label:'Halsua'}, 
      {value:'Hamina', label:'Hamina'}, 
      {value:'Hammarland', label:'Hammarland'}, 
      {value:'Hankasalmi', label:'Hankasalmi'}, 
      {value:'Hanko', label:'Hanko'}, 
      {value:'Harjavalta', label:'Harjavalta'}, 
      {value:'Hartola', label:'Hartola'}, 
      {value:'Hattula', label:'Hattula'}, 
      {value:'Hausjärvi', label:'Hausjärvi'}, 
      {value:'Heinola', label:'Heinola'}, 
      {value:'Heinävesi', label:'Heinävesi'}, 
      {value:'Helsinki', label:'Helsinki'}, 
      {value:'Hirvensalmi', label:'Hirvensalmi'}, 
      {value:'Hollola', label:'Hollola'}, 
      {value:'Huittinen', label:'Huittinen'}, 
      {value:'Humppila', label:'Humppila'}, 
      {value:'Hyrynsalmi', label:'Hyrynsalmi'}, 
      {value:'Hyvinkää', label:'Hyvinkää'}, 
      {value:'Hämeenkyrö', label:'Hämeenkyrö'}, 
      {value:'Hämeenlinna', label:'Hämeenlinna'}, 
      {value:'Ii', label:'Ii'}, 
      {value:'Iisalmi', label:'Iisalmi'}, 
      {value:'Iitti', label:'Iitti'}, 
      {value:'Ikaalinen', label:'Ikaalinen'}, 
      {value:'Ilmajoki', label:'Ilmajoki'}, 
      {value:'Ilomantsi', label:'Ilomantsi'}, 
      {value:'Imatra', label:'Imatra'}, 
      {value:'Inari', label:'Inari'}, 
      {value:'Inkoo', label:'Inkoo'}, 
      {value:'Isojoki', label:'Isojoki'}, 
      {value:'Isokyrö', label:'Isokyrö'}, 
      {value:'Janakkala', label:'Janakkala'}, 
      {value:'Joensuu', label:'Joensuu'}, 
      {value:'Jokioinen', label:'Jokioinen'}, 
      {value:'Jomala', label:'Jomala'}, 
      {value:'Joroinen', label:'Joroinen'}, 
      {value:'Joutsa', label:'Joutsa'}, 
      {value:'Juuka', label:'Juuka'}, 
      {value:'Juupajoki', label:'Juupajoki'}, 
      {value:'Juva', label:'Juva'}, 
      {value:'Jyväskylä', label:'Jyväskylä'}, 
      {value:'Jämijärvi', label:'Jämijärvi'}, 
      {value:'Jämsä', label:'Jämsä'}, 
      {value:'Järvenpää', label:'Järvenpää'}, 
      {value:'Kaarina', label:'Kaarina'}, 
      {value:'Kaavi', label:'Kaavi'}, 
      {value:'Kajaani', label:'Kajaani'}, 
      {value:'Kalajoki', label:'Kalajoki'}, 
      {value:'Kangasala', label:'Kangasala'}, 
      {value:'Kangasniemi', label:'Kangasniemi'}, 
      {value:'Kankaanpää', label:'Kankaanpää'}, 
      {value:'Kannonkoski', label:'Kannonkoski'}, 
      {value:'Kannus', label:'Kannus'}, 
      {value:'Karijoki', label:'Karijoki'}, 
      {value:'Karkkila', label:'Karkkila'}, 
      {value:'Karstula', label:'Karstula'}, 
      {value:'Karvia', label:'Karvia'}, 
      {value:'Kaskinen', label:'Kaskinen'}, 
      {value:'Kauhajoki', label:'Kauhajoki'}, 
      {value:'Kauhava', label:'Kauhava'}, 
      {value:'Kauniainen', label:'Kauniainen'}, 
      {value:'Kaustinen', label:'Kaustinen'}, 
      {value:'Keitele', label:'Keitele'}, 
      {value:'Kemi', label:'Kemi'}, 
      {value:'Kemijärvi', label:'Kemijärvi'}, 
      {value:'Keminmaa', label:'Keminmaa'}, 
      {value:'Kemiönsaari', label:'Kemiönsaari'}, 
      {value:'Kempele', label:'Kempele'}, 
      {value:'Kerava', label:'Kerava'}, 
      {value:'Keuruu', label:'Keuruu'}, 
      {value:'Kihniö', label:'Kihniö'}, 
      {value:'Kinnula', label:'Kinnula'}, 
      {value:'Kirkkonummi', label:'Kirkkonummi'}, 
      {value:'Kitee', label:'Kitee'}, 
      {value:'Kittilä', label:'Kittilä'}, 
      {value:'Kiuruvesi', label:'Kiuruvesi'}, 
      {value:'Kivijärvi', label:'Kivijärvi'}, 
      {value:'Kokemäki', label:'Kokemäki'}, 
      {value:'Kokkola', label:'Kokkola'}, 
      {value:'Kolari', label:'Kolari'}, 
      {value:'Konnevesi', label:'Konnevesi'}, 
      {value:'Kontiolahti', label:'Kontiolahti'}, 
      {value:'Korsnäs', label:'Korsnäs'}, 
      {value:'Koski Tl', label:'Koski Tl'}, 
      {value:'Kotka', label:'Kotka'}, 
      {value:'Kouvola', label:'Kouvola'}, 
      {value:'Kristiinankaupunki', label:'Kristiinankaupunki'}, 
      {value:'Kruunupyy', label:'Kruunupyy'}, 
      {value:'Kuhmo', label:'Kuhmo'}, 
      {value:'Kuhmoinen', label:'Kuhmoinen'}, 
      {value:'Kumlinge', label:'Kumlinge'}, 
      {value:'Kuopio', label:'Kuopio'}, 
      {value:'Kuortane', label:'Kuortane'}, 
      {value:'Kurikka', label:'Kurikka'}, 
      {value:'Kustavi', label:'Kustavi'}, 
      {value:'Kuusamo', label:'Kuusamo'}, 
      {value:'Kyyjärvi', label:'Kyyjärvi'}, 
      {value:'Kärkölä', label:'Kärkölä'}, 
      {value:'Kärsämäki', label:'Kärsämäki'}, 
      {value:'Kökar', label:'Kökar'}, 
      {value:'Lahti', label:'Lahti'}, 
      {value:'Laihia', label:'Laihia'}, 
      {value:'Laitila', label:'Laitila'}, 
      {value:'Lapinjärvi', label:'Lapinjärvi'}, 
      {value:'Lapinlahti', label:'Lapinlahti'}, 
      {value:'Lappajärvi', label:'Lappajärvi'}, 
      {value:'Lappeenranta', label:'Lappeenranta'}, 
      {value:'Lapua', label:'Lapua'}, 
      {value:'Laukaa', label:'Laukaa'}, 
      {value:'Lemi', label:'Lemi'}, 
      {value:'Lemland', label:'Lemland'}, 
      {value:'Lempäälä', label:'Lempäälä'}, 
      {value:'Leppävirta', label:'Leppävirta'}, 
      {value:'Lestijärvi', label:'Lestijärvi'}, 
      {value:'Lieksa', label:'Lieksa'}, 
      {value:'Lieto', label:'Lieto'}, 
      {value:'Liminka', label:'Liminka'}, 
      {value:'Liperi', label:'Liperi'}, 
      {value:'Lohja', label:'Lohja'}, 
      {value:'Loimaa', label:'Loimaa'}, 
      {value:'Loppi', label:'Loppi'}, 
      {value:'Loviisa', label:'Loviisa'}, 
      {value:'Luhanka', label:'Luhanka'}, 
      {value:'Lumijoki', label:'Lumijoki'}, 
      {value:'Lumparland', label:'Lumparland'}, 
      {value:'Luoto', label:'Luoto'}, 
      {value:'Luumäki', label:'Luumäki'}, 
      {value:'Maalahti', label:'Maalahti'}, 
      {value:'Maarianhamina – Mariehamn', label:'Maarianhamina – Mariehamn'}, 
      {value:'Marttila', label:'Marttila'}, 
      {value:'Masku', label:'Masku'}, 
      {value:'Merijärvi', label:'Merijärvi'}, 
      {value:'Merikarvia', label:'Merikarvia'}, 
      {value:'Miehikkälä', label:'Miehikkälä'}, 
      {value:'Mikkeli', label:'Mikkeli'}, 
      {value:'Muhos', label:'Muhos'}, 
      {value:'Multia', label:'Multia'}, 
      {value:'Muonio', label:'Muonio'}, 
      {value:'Mustasaari', label:'Mustasaari'}, 
      {value:'Muurame', label:'Muurame'}, 
      {value:'Mynämäki', label:'Mynämäki'}, 
      {value:'Myrskylä', label:'Myrskylä'}, 
      {value:'Mäntsälä', label:'Mäntsälä'}, 
      {value:'Mänttä-Vilppula', label:'Mänttä-Vilppula'}, 
      {value:'Mäntyharju', label:'Mäntyharju'}, 
      {value:'Naantali', label:'Naantali'}, 
      {value:'Nakkila', label:'Nakkila'}, 
      {value:'Nivala', label:'Nivala'}, 
      {value:'Nokia', label:'Nokia'}, 
      {value:'Nousiainen', label:'Nousiainen'}, 
      {value:'Nurmes', label:'Nurmes'}, 
      {value:'Nurmijärvi', label:'Nurmijärvi'}, 
      {value:'Närpiö', label:'Närpiö'}, 
      {value:'Orimattila', label:'Orimattila'}, 
      {value:'Oripää', label:'Oripää'}, 
      {value:'Orivesi', label:'Orivesi'}, 
      {value:'Oulainen', label:'Oulainen'}, 
      {value:'Oulu', label:'Oulu'}, 
      {value:'Outokumpu', label:'Outokumpu'}, 
      {value:'Padasjoki', label:'Padasjoki'}, 
      {value:'Paimio', label:'Paimio'}, 
      {value:'Paltamo', label:'Paltamo'}, 
      {value:'Parainen', label:'Parainen'}, 
      {value:'Parikkala', label:'Parikkala'}, 
      {value:'Parkano', label:'Parkano'}, 
      {value:'Pedersöre', label:'Pedersöre'}, 
      {value:'Pelkosenniemi', label:'Pelkosenniemi'}, 
      {value:'Pello', label:'Pello'}, 
      {value:'Perho', label:'Perho'}, 
      {value:'Pertunmaa', label:'Pertunmaa'}, 
      {value:'Petäjävesi', label:'Petäjävesi'}, 
      {value:'Pieksämäki', label:'Pieksämäki'}, 
      {value:'Pielavesi', label:'Pielavesi'}, 
      {value:'Pietarsaari', label:'Pietarsaari'}, 
      {value:'Pihtipudas', label:'Pihtipudas'}, 
      {value:'Pirkkala', label:'Pirkkala'}, 
      {value:'Polvijärvi', label:'Polvijärvi'}, 
      {value:'Pomarkku', label:'Pomarkku'}, 
      {value:'Pori', label:'Pori'}, 
      {value:'Pornainen', label:'Pornainen'}, 
      {value:'Porvoo', label:'Porvoo'}, 
      {value:'Posio', label:'Posio'}, 
      {value:'Pudasjärvi', label:'Pudasjärvi'}, 
      {value:'Pukkila', label:'Pukkila'}, 
      {value:'Punkalaidun', label:'Punkalaidun'}, 
      {value:'Puolanka', label:'Puolanka'}, 
      {value:'Puumala', label:'Puumala'}, 
      {value:'Pyhtää', label:'Pyhtää'}, 
      {value:'Pyhäjoki', label:'Pyhäjoki'}, 
      {value:'Pyhäjärvi', label:'Pyhäjärvi'}, 
      {value:'Pyhäntä', label:'Pyhäntä'}, 
      {value:'Pyhäranta', label:'Pyhäranta'}, 
      {value:'Pälkäne', label:'Pälkäne'}, 
      {value:'Pöytyä', label:'Pöytyä'}, 
      {value:'Raahe', label:'Raahe'}, 
      {value:'Raasepori', label:'Raasepori'}, 
      {value:'Raisio', label:'Raisio'}, 
      {value:'Rantasalmi', label:'Rantasalmi'}, 
      {value:'Ranua', label:'Ranua'}, 
      {value:'Rauma', label:'Rauma'}, 
      {value:'Rautalampi', label:'Rautalampi'}, 
      {value:'Rautavaara', label:'Rautavaara'}, 
      {value:'Rautjärvi', label:'Rautjärvi'}, 
      {value:'Reisjärvi', label:'Reisjärvi'}, 
      {value:'Riihimäki', label:'Riihimäki'}, 
      {value:'Ristijärvi', label:'Ristijärvi'}, 
      {value:'Rovaniemi', label:'Rovaniemi'}, 
      {value:'Ruokolahti', label:'Ruokolahti'}, 
      {value:'Ruovesi', label:'Ruovesi'}, 
      {value:'Rusko', label:'Rusko'}, 
      {value:'Rääkkylä', label:'Rääkkylä'}, 
      {value:'Saarijärvi', label:'Saarijärvi'}, 
      {value:'Salla', label:'Salla'}, 
      {value:'Salo', label:'Salo'}, 
      {value:'Saltvik', label:'Saltvik'}, 
      {value:'Sastamala', label:'Sastamala'}, 
      {value:'Sauvo', label:'Sauvo'}, 
      {value:'Savitaipale', label:'Savitaipale'}, 
      {value:'Savonlinna', label:'Savonlinna'}, 
      {value:'Savukoski', label:'Savukoski'}, 
      {value:'Seinäjoki', label:'Seinäjoki'}, 
      {value:'Sievi', label:'Sievi'}, 
      {value:'Siikainen', label:'Siikainen'}, 
      {value:'Siikajoki', label:'Siikajoki'}, 
      {value:'Siikalatva', label:'Siikalatva'}, 
      {value:'Siilinjärvi', label:'Siilinjärvi'}, 
      {value:'Simo', label:'Simo'}, 
      {value:'Sipoo', label:'Sipoo'}, 
      {value:'Siuntio', label:'Siuntio'}, 
      {value:'Sodankylä', label:'Sodankylä'}, 
      {value:'Soini', label:'Soini'}, 
      {value:'Somero', label:'Somero'}, 
      {value:'Sonkajärvi', label:'Sonkajärvi'}, 
      {value:'Sotkamo', label:'Sotkamo'}, 
      {value:'Sottunga', label:'Sottunga'}, 
      {value:'Sulkava', label:'Sulkava'}, 
      {value:'Sund', label:'Sund'}, 
      {value:'Suomussalmi', label:'Suomussalmi'}, 
      {value:'Suonenjoki', label:'Suonenjoki'}, 
      {value:'Sysmä', label:'Sysmä'}, 
      {value:'Säkylä', label:'Säkylä'}, 
      {value:'Taipalsaari', label:'Taipalsaari'}, 
      {value:'Taivalkoski', label:'Taivalkoski'}, 
      {value:'Taivassalo', label:'Taivassalo'}, 
      {value:'Tammela', label:'Tammela'}, 
      {value:'Tampere', label:'Tampere'}, 
      {value:'Tervo', label:'Tervo'}, 
      {value:'Tervola', label:'Tervola'}, 
      {value:'Teuva', label:'Teuva'}, 
      {value:'Tohmajärvi', label:'Tohmajärvi'}, 
      {value:'Toholampi', label:'Toholampi'}, 
      {value:'Toivakka', label:'Toivakka'}, 
      {value:'Tornio', label:'Tornio'}, 
      {value:'Turku', label:'Turku'}, 
      {value:'Tuusniemi', label:'Tuusniemi'}, 
      {value:'Tuusula', label:'Tuusula'}, 
      {value:'Tyrnävä', label:'Tyrnävä'}, 
      {value:'Ulvila', label:'Ulvila'}, 
      {value:'Urjala', label:'Urjala'}, 
      {value:'Utajärvi', label:'Utajärvi'}, 
      {value:'Utsjoki', label:'Utsjoki'}, 
      {value:'Uurainen', label:'Uurainen'}, 
      {value:'Uusikaarlepyy', label:'Uusikaarlepyy'}, 
      {value:'Uusikaupunki', label:'Uusikaupunki'}, 
      {value:'Vaala', label:'Vaala'}, 
      {value:'Vaasa', label:'Vaasa'}, 
      {value:'Valkeakoski', label:'Valkeakoski'}, 
      {value:'Vantaa', label:'Vantaa'}, 
      {value:'Varkaus', label:'Varkaus'}, 
      {value:'Vehmaa', label:'Vehmaa'}, 
      {value:'Vesanto', label:'Vesanto'}, 
      {value:'Vesilahti', label:'Vesilahti'}, 
      {value:'Veteli', label:'Veteli'}, 
      {value:'Vieremä', label:'Vieremä'}, 
      {value:'Vihti', label:'Vihti'}, 
      {value:'Viitasaari', label:'Viitasaari'}, 
      {value:'Vimpeli', label:'Vimpeli'}, 
      {value:'Virolahti', label:'Virolahti'}, 
      {value:'Virrat', label:'Virrat'}, 
      {value:'Vårdö', label:'Vårdö'}, 
      {value:'Vöyri', label:'Vöyri'}, 
      {value:'Ylitornio', label:'Ylitornio'}, 
      {value:'Ylivieska', label:'Ylivieska'}, 
      {value:'Ylöjärvi', label:'Ylöjärvi'}, 
      {value:'Ypäjä', label:'Ypäjä'}, 
      {value:'Ähtäri', label:'Ähtäri'}, 
      {value:'Äänekoski', label:'Äänekoski'}, 
      

    ];

    return (
      <div className="ui container">
{/*         {alert}
        <AlertDismissible /> */}

        <NavLink exact to="/vessat/uusi" className="ui button green">
        <i className="icon-edit"></i> Lisää uusi vessa
        </NavLink>
        <h3>Vessat</h3>

        {/* Add the genre dropdown menu */}
        <div>
          <Select
            options={genreOptions}
            onChange={this.handleGenreChange}
            placeholder="Valitse paikkakunta"
          />
        </div>


        <div>
          <label>
            <input
              type="checkbox"
              checked={this.state.filterByIlmainen}
              onChange={this.handleilmainenFilterChange}
            />
            Näytä vain ilmaiset vessat
          </label>
        </div>


        {/* Add the checkbox to filter by rating */}
{/*         <div>
          <label>
            <input
              type="checkbox"
              checked={this.state.filterByRating}
              onChange={this.handleFilterChange}
            />
            Näytä vain yli 5:n ratingin vessat
          </label>
        </div> */}

        <div>
          <label>
            <input
              type="checkbox"
              checked={this.state.filterByEsteeton}
              onChange={this.handleEsteetonFilterChange}
            />
            Näytä vain vessat joissa myös inva-WC
          </label>
        </div>



        <div>
          <label>
            <input
              type="checkbox"
              checked={this.state.filterByLapsi}
              onChange={this.handleLapsiFilterChange}
            />
            Näytä vain vessat joissa vauvanhoitomahdollisuus
          </label>
        </div>

                {/* Add the checkbox to filter by "created within last week" */}
{/*                 <div>
          <label>
            <input
              type="checkbox"
              checked={this.state.filterByWeek}
              onChange={this.handleWeekFilterChange}
            />
            Näytä vain viime viikon aikana lisätyt vessat
          </label>
        </div> */}



{/*          <Select
          options={this.state.bands}
          onChange={this.handleChange}
          placeholder="Kirjoita bändin nimi tai valitse listalta"
        />  */}

        {filteredBands.length > 0 ? grid : emptyMessage}
      </div>
    );
  }
}

BandsListPage.propTypes = {
  bands: PropTypes.array.isRequired,
  fetchBands: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
	return {
	  bands: state.bands,
	  history: ownProps.history,
	};
  }

function AlertDismissible() {
  const [show, setShow] = useState(true);
  return (
    <>

      <Alert show={show} variant="success">
        <strong>Huom!</strong> Huoltokatko 31.4.2023!
        <span
          className="closebtn"
          onClick={() => setShow(false)}
        >
          <span>&times;</span>
        </span>
      </Alert>
    </>
  );
}

export default connect(mapStateToProps, { fetchBands })(BandsListPage);