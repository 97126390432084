import React from 'react';
import { connect } from 'react-redux';
import { Redirect, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { addFeedback, updateFeedback } from '../../actions/feedback';

class FeedbackForm extends React.Component {
	constructor(props) {
	  super(props);
	  const captcha = this.generateCaptcha();
	  this.state = {
		id: this.props.feedback ? this.props.feedback.id : null,
		description: this.props.feedback ? this.props.feedback.description : '',
		feedback_id: this.props.match.params.id, //this.props.feedback ? this.props.feedback.feedback_id : '',
		errors: {},
		loading: false,
		redirect: false,
		captcha: captcha,
		captchaError: '',
		captchaInput: '',
	  };
	}



	componentWillReceiveProps = (nextProps) => {
		this.setState({
			id: nextProps.id,
			description: nextProps.description,
		})
	}

	handleChange = (e) => {
		if (e.target.name === 'rating') {
			this.setState({ ratingSelected: true });
		  }
		this.setState({ [e.target.name]: e.target.value });
	}

	handleSubmit = (e) => {

		e.preventDefault();
		let errors = {};

		// Validation

		if (this.state.description === '') errors.description = "Täytä tämä tieto";
		//if (this.state.year === '') errors.year = "This field can't be empty";
		//if (this.state.cover === '') errors.cover = "This field can't be empty";
		if (!this.validateCaptcha()) {
			this.setState({ captchaError: 'Laskutoimitus väärin.' });
			return;
		  } else {
			this.setState({ captchaError: '' });
		  }
		// Fill the errors object state
		this.setState({ errors });

		// Proceed if everything is OK
		if (Object.keys(errors).length === 0) {
			this.setState({ loading: true }); // Set up the loading
			const { id, description } = this.state;
			if (id && id > 0)
				this.props.updateFeedback({ id,description }).then(() => this.setState({ redirect: true }));
			else
				this.props.addFeedback({description }).then(() => this.setState({ redirect: true }));
		}
	}



	  generateCaptcha() {
		const num1 = Math.floor(Math.random() * 10);
		const num2 = Math.floor(Math.random() * 10);
		return `${num1} + ${num2}`;
	  }
	  handleCaptchaChange = (e) => {
		this.setState({ captchaInput: e.target.value });
	  };
		
	  validateCaptcha() {
		const [num1, , num2] = this.state.captcha.split(' ');
		const sum = parseInt(num1) + parseInt(num2);
		return parseInt(this.state.captchaInput) === sum;
	  }

	render() {
		return (
			<div>
				{
					// Redirect if some action has worked succesfully, render if not
					this.state.redirect ?
						<Redirect to={`/`} /> :
						<div className="ui container">
		
							

							<form className={classnames('ui', 'form', { 'loading': this.state.loading })} onSubmit={this.handleSubmit}>
								<h3 className="ui dividing header">Anna palautetta tai kehitysehdotuksia palvelusta</h3>

								{!!this.state.errors.global && <div className="ui negative message"><p>{this.state.errors.global}</p></div>}

								<div className={classnames("field", { error: !!this.state.errors.description })}>
									<label htmlFor="description">Palaute</label>
									<textarea
										id="description" name="description"
										value={this.state.description}
										className="ui input"
										maxLength={3000}
										placeholder="Esim. 'Palvelussa voisi olla oma hakunsa sukupuolineutraaleille vessoille!'"
										onChange={this.handleChange}
										style={{height: '115px'}}
									></textarea>
									<span>{this.state.errors.description}</span>
								</div>


									<div className={classnames("field", { error: !!this.state.captchaError })}>
									<label htmlFor="captchaInput">{this.state.captcha} = ?</label>
									<input
										id="captchaInput"
										name="captchaInput"
										value={this.state.captchaInput}
										className="ui input"
										placeholder="Ethän ole robotti; anna yllä olevan laskun vastaus."
										onChange={this.handleCaptchaChange}
									/>
									<span>{this.state.captchaError}</span>
									</div>

								<div className="field">
									<button type="submit" className="ui button green"><i className="icon-save"></i> Lähetä palaute</button>
								</div>
							</form>		


							<br></br>
							<h3 className="ui dividing header"></h3>
							<p><i>Palvelun sisältö on koostettu vapaaehtoisvoimin ja palvelun tekijä ei ota vastuuta tietojen oikeellisuudesta.</i></p>
						
						</div>
				}
			</div>
		)
	}
}

FeedbackForm.propTypes = {
	updateFeedback: PropTypes.func.isRequired,
	addFeedback: PropTypes.func.isRequired
};

function mapStateToProps(state, props) {
	if (props.match.params && props.match.params.id > 0) {
		const feedback = state.feedback.find(item => item.id == props.match.params.id);
		//const album = band ? band.albums.find(item => item.id == props.match.params.album_id) : null;
		return { feedback: feedback };
	}

	return { feedback: null };
}

export default connect(mapStateToProps, { addFeedback, updateFeedback })(FeedbackForm);
