/* import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, NavLink } from 'react-router-dom';

import { fetchBandAlbums } from '../../../actions/bands';
import AlbumCard from './AlbumCard';

class AlbumsList extends React.Component {

	render() {
		let albumCards = "";
		if (!!this.props.band && !!this.props.band.albums) {
			albumCards = (
				<div className="ui three cards">
					{
						!!this.props.band.albums && this.props.band.albums.map((album) => {
							return (<AlbumCard key={this.props.band.id} band={this.props.band} album={album} />);
						})
					}
				</div>
			);
		}

		const emptyMessage = (
			<div className="ui info message">There is no albums registered yet.</div>
		);

		return (
			<div className="ui container">
				{
					!this.props.band ? //|| !this.props.band.albums ?
						<Redirect to="/bands" /> :
						<div>
							<h1>{this.props.band.title}</h1>
							<div>
								<NavLink exact to="/bands/" className="ui button">Back to bands list</NavLink>
								<NavLink exact to={`/band/${this.props.band.id}/album/new`} className="ui button primary">Add New</NavLink>
								<br /><br />
								{ !!this.props.band.albums && this.props.band.albums.length > 0 ? albumCards : emptyMessage }
							</div>
						</div>
				}
			</div>
		);
	}
}

function mapStateToProps(state, props) {
	return { band: state.bands.filter(item => item.id == Number(props.match.params.id))[0] };
}

export default connect(mapStateToProps, { fetchBandAlbums })(AlbumsList);
 */



import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, NavLink } from 'react-router-dom';
import { Comment, Segment } from 'semantic-ui-react';

import { fetchBandAlbums } from '../../../actions/bands';
import AlbumCard from './AlbumCard';
function formatDate(dateString) {
	const date = new Date(dateString);
	date.setHours(date.getHours());
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const year = date.getFullYear();
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
  
	return `${day}.${month}.${year} ${hours}:${minutes}`;
  }
  class AlbumsList extends React.Component {

	render() {
	  let commentSection = "";
	  if (!!this.props.band && !!this.props.band.albums) {
		const sortedAlbums = this.props.band.albums.sort((a, b) => {
		  return new Date(b.createdAt) - new Date(a.createdAt);
		});
  
		commentSection = (
			<Comment.Group>
			{sortedAlbums.map((album) => (
			  <Segment key={album.id}>
				<Comment>
				  <Comment.Content>
					<Comment.Author as='a'>{album.title}</Comment.Author>
					<Comment.Metadata>
					  <div>{formatDate(album.createdAt)} - </div>
					  <div> <strong> <i className="icon-star icon-large"></i> </strong>{album.rating}/10</div>
					</Comment.Metadata>
					<Comment.Text>{album.description}</Comment.Text>
					<Comment.Metadata><br></br>
					  <div><p><i className="icon wheelchair"></i>Helppokulkuinen: {album.helppo === 1 ? 'Ei' : album.helppo === 2 ? 'Kyllä' : 'En osaa sanoa'}</p>
					  <p><i className="icon heart"></i>Siisti: {album.siisti === 1 ? 'Ei' : album.siisti === 2 ? 'Kyllä' : 'En osaa sanoa'}</p></div>
					</Comment.Metadata>
				  </Comment.Content>
				</Comment>
			  </Segment>
			))}
		  </Comment.Group>
		  
		);
	  }
  
	  const emptyMessage = (
		<div className="ui info message">Ei arvosteluja.</div>
	  );
  
	  return (
		<div className="ui container">
		  {
			!this.props.band ? //|| !this.props.band.albums ?
			  <Redirect to="/vessat" /> :
			  <div>
				
				<div>
				<NavLink exact to={`/vessa/${this.props.band.id}`} className="ui button blue"><i className="icon-tasks"></i> Takaisin vessan tietoihin</NavLink>
				  <NavLink exact to={`/vessa/${this.props.band.id}/arvostelu/uusi`} className="ui button green"><i className="icon discussions"></i> Lisää arvostelu</NavLink>
				 
				  <h3>{this.props.band.title}</h3>
				  { !!this.props.band.albums && this.props.band.albums.length > 0 ? commentSection : emptyMessage }
				</div>
			  </div>
		  }
		</div>
	  );
	}
  }
  
  function mapStateToProps(state, props) {
	return { band: state.bands.filter(item => item.id == Number(props.match.params.id))[0] };
  }
  
  export default connect(mapStateToProps, { fetchBandAlbums })(AlbumsList);
  