import React from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import MapWithMarkers from './MapWithMarkers';
class Info extends React.Component {
	render() {
		return (
			<div>
				<div className="ui container text">
				<div>
      <h3>Vessat kartalla</h3>
      <MapWithMarkers />
    </div><br></br>
					<p>Valitse vessa suoraan kartalta klikkaamalla.</p>
				</div>
			</div>
		);
	}
}

export default Info;
