import React from 'react';
import ReactDOM from 'react-dom';
// Routing
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
// Redux
import { createStore, applyMiddleware } from 'redux';
// Redux Middlewares
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
// Redux Provider for React
import { Provider } from 'react-redux';
// JSON Web Token
import jwt from 'jsonwebtoken';

// Root reducer to use in Redux Store
import rootReducer from './reducers/rootReducer';
// The application, high order component
import App from './components/App';
// Application routes as external component
import Routes from './routes/Routes';
// Style
import './style/index.css';
import './style/tableStyles.css';
import 'semantic-ui-css/semantic.min.css';
document.head.innerHTML = document.head.innerHTML + '<link href="//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css" rel="stylesheet">'

// Browser history
const history = createBrowserHistory();

// Redux store
const store = createStore(
	rootReducer,
	composeWithDevTools(
		applyMiddleware(thunk)
	)
);

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<App>
				<Routes />
			</App>
		</Router>
	</Provider>,
	document.getElementById('root')
);

// Register service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  });
}
